import httpClient from '@/core/api/api.service'

export default function useEvents() {
  const getEvents = async (search = null) => {
    try {
      const { data } = await httpClient.get('events', null, { search })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getEvent = async id => {
    try {
      const { data } = await httpClient.get(`events/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getEventBySlug = async slug => {
    try {
      const { data } = await httpClient.get(`events/slug/${slug}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const createEvent = async body => {
    try {
      const { data } = await httpClient.save(`events`, body)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const editEvent = async (id, body) => {
    try {
      const { data } = await httpClient.update(`events`, id, body)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const reportEvent = async id => {
    try {
      const { data } = await httpClient.save(`events/${id}/report`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const visitEvent = async id => {
    try {
      const { data } = await httpClient.save(`events/${id}/visit`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const commentEvent = async (id, comment, image) => {
    try {
      const { data } = await httpClient.save(`events/${id}/comment`, {
        message: comment,
        image,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const likeComment = async id => {
    try {
      const { data } = await httpClient.save(`events/${id}/like`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const checkSubmitIsAvailable = async () => {
    try {
      const { data } = await httpClient.get(`events/check-submit`)

      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    getEvents,
    getEvent,
    getEventBySlug,
    createEvent,
    editEvent,
    reportEvent,
    visitEvent,
    commentEvent,
    likeComment,
    checkSubmitIsAvailable,
  }
}
