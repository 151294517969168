<template>
  <div class="pet-select" :class="{ customPets }">
    <div v-if="myPets.length" class="dogs">
      <!--align main pet to center-->
      <div v-if="myPets.length % 2 == 0" class="empty"></div>
      <div class="dog-box" v-for="pet in myPets" :key="pet.id">
        <div
          :key="pet.id"
          class="dog"
          v-bind:class="{
            noDoxSelected: pet.selectedButNoDox,
            active: pet.id === activePetId,
            walking: pet.selected,
            notwalking: !pet.selected && pet.selectedButNoDox === undefined,
          }"
        >
          <img :src="pet.avatar" alt="" @click="selectPet(pet, index)" />
          <span class="dog-status" v-bind:class="{ active: pet.selected }" v-if="!disabled"
            ><ion-img
              :src="
                pet.selected
                  ? require('@/assets/images/icons/transparent_checkmark.png')
                  : require('@/assets/images/icons/circle-plus2.svg')
              "
            ></ion-img
          ></span>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import usePets from '@/composables/pets'
import useNavigation from '@/composables/navigation'
import { useRoute } from 'vue-router'
import { PET_WALKING_ROUTE } from '@/constants/routes'

export default defineComponent({
  name: 'PetSelector',
  props: {
    disabled: Boolean,
    pets: Array,
    newDesign: Boolean,
    // saveToBeckend: Boolean,
  },
  components: {
    // IonIcon,
    // IonButton,
    // IonRippleEffect,
  },
  emits: ['onSelect'],
  setup(props, { emit }) {
    const { PETS_STATE, activePet, setPetWalking, givePointToPet } = usePets()
    const { openBrowserLink } = useNavigation()
    const route = useRoute()
    // const selectedPetIds = ref([])
    const activePetId = computed(() => PETS_STATE.activePetId)
    const myPets = ref([])
    const selectedPet = ref({})
    const activePetSelected = ref(false)
    const propPets = computed(() => props.pets)

    const customPets = computed(() => props.newDesign)

    const selectPet = pet => {
      if (props.disabled) return

      selectedPet.value = pet
      var selectedIndex = getSelectedIndex(pet.id)
      // ha aktív petnek nincs dox akkor, nem tud változtatni a kijelölésen
      // hogy ne tudja leszedni indet séta közben
      if (activePetSelected.value) {
        if (selectedIndex === -1) {
          console.error('not in my pets:', pet)
        } else {
          if (pet.id != PETS_STATE.activePetId) {
            const selected = myPets.value[selectedIndex].selected
            setPetWalking(pet, !selected)

            // if the pet is selected to walk give him a point
            if (!selected === true) {
              givePointToPet(pet.id, 'start')
            }
            // if (props.saveToBeckend) {
            //   // update
            //   refreshWalking()
            // }
            myPets.value[selectedIndex] = { ...pet, selected: !selected }
          }
        }
      }

      // ha nincs dox a főpeten, akkor nincs szelected esemény se
      // pl. hogy ne záródjon be a séta popup
      if (activePetSelected.value) {
        emit('onSelect', {
          selectedPet: selectedPet.value,
        })
      }
    }

    const getSelectedIndex = petId => {
      return myPets.value.findIndex(p => p.id === petId)
    }

    const initMyPets = () => {
      console.log('initpet')
      const petsOrdered = []
      // ha aktív a séta, akkor nem lesz automatikusan hozzáadva az aktív pet, ha eddig nem volt
      activePetSelected.value = PETS_STATE.activeWalking
        ? PETS_STATE.petsWalking.findIndex(id => id == activePet.value.id) != -1
        : true

      if (activePet.value) {
        petsOrdered.push({ ...activePet.value, selected: activePetSelected.value })
      }

      setPetWalking(activePet.value, activePetSelected.value)

      let petsArray = PETS_STATE.pets

      if (propPets.value) {
        petsArray = propPets.value

        if (!activePet.value && propPets.value.length > 0) {
          activePet.value = propPets.value[0]
          activePetId.value = propPets.value[0].id
          petsOrdered.push({ ...propPets.value[0], selected: true })

          petsArray = propPets.value.slice(1)
        }
      }

      petsArray.forEach(pet => {
        if (pet.id !== activePetId.value) {
          const selected = PETS_STATE.petsWalking.findIndex(id => id == pet.id) != -1
          const petWithSelected = { ...pet, selected }

          if (!pet) return

          // setPetWalking(pet, selected)
          if (petsOrdered.length % 2 == 0) {
            petsOrdered.unshift(petWithSelected)
          } else {
            petsOrdered.push(petWithSelected)
          }
        }
      })

      console.log('my pets', petsOrdered)

      myPets.value = petsOrdered
    }

    watch(route, () => {
      if (
        route.name == PET_WALKING_ROUTE
        // &&
        //   router.options.history.state.forward !== '/dox-data-read-map' &&
        //   activePet.value.has_dox
      ) {
        initMyPets()
      }
    })

    watch(activePetId, () => {
      console.log('active pet id changed', activePetId.value)
      initMyPets()
    })

    watch(propPets, () => initMyPets())

    onMounted(() => {
      // active pet always selected
      initMyPets()
    })

    return {
      PETS_STATE,
      myPets,
      activePetId,
      selectPet,
      selectedPet,
      openBrowserLink,
      initMyPets,
      customPets,
    }
  },
})
</script>

<style lang="scss" scoped>
.noDoxAnimClass {
  animation: noDoxAnim 2s;
}
.menuActive {
  .pet-select {
    margin-top: 15px;
    height: auto;
  }
}
.dog-box {
  align-items: center;
  vertical-align: middle;
  text-align: center;
  min-width: 65px;
  width: 65px;
}
.pet-select {
  height: 250px;

  .dogs {
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    scrollbar-width: none;
    height: 110px;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    .empty {
      min-width: 75px;
      min-height: 75px;
      width: 75px;
      height: 75px;
      position: relative;
    }

    .dog {
      min-width: 65px;
      min-height: 65px;
      width: 65px;
      height: 65px;
      margin-left: -8px;
      position: relative;

      .dog-status {
        position: absolute;
        right: 0;
        bottom: -2px;
        width: 13px;
        height: 13px;
        color: #888;

        &.active {
          color: #fd3e43;
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }

      &.notwalking {
        animation: dogGrowBack 1s;
      }

      &.noDoxSelected {
        animation: noDoxAnim 1s;
      }

      &.walking {
        min-width: 75px;
        min-height: 75px;
        width: 75px;
        height: 75px;
        animation: dogGrow 1s;
        z-index: 9;

        .dog-status {
          bottom: -5px;
          //right: 3px;
        }
      }

      &.active {
        min-width: 85px;
        min-height: 85px;
        width: 85px;
        height: 85px;
        z-index: 10;
        margin-right: 5px;

        .dog-status {
          bottom: -4px;
          //right: 7px;
        }
      }

      img {
        border: 3px solid #fff;
        border-radius: 50%;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        background: #fff;
      }
    }
  }

  &.customPets {
    .dogs {
      margin-left: 8px;
    }

    .dog {
      min-width: 70px;
      min-height: 70px;
      width: 70px;
      height: 70px;

      img {
        border: 7px solid #fff;
        filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
      }

      &.walking {
        min-width: 88px;
        min-height: 88px;
        width: 88px;
        height: 88px;
        margin-left: -16px;
      }
    }
  }
}
@keyframes dogGrow {
  from {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}
@keyframes dogGrowBack {
  from {
    transform: scale(1.15);
  }
  to {
    transform: scale(1);
  }
}

@keyframes noDoxAnim {
  0% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  10% {
    transform: translate(-2px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(2px, 2px) rotate(1deg);
  }
  30% {
    transform: translate(-2px, -2px) rotate(0deg);
  }
  40% {
    transform: translate(2px, 2px) rotate(1deg);
  }
  50% {
    transform: translate(-2px, -2px) rotate(-1deg);
  }
  60% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  70% {
    transform: translate(-2px, -2px) rotate(-1deg);
  }
  80% {
    transform: translate(2px, 2px) rotate(1deg);
  }
  90% {
    transform: translate(-2px, -2px) rotate(0deg);
  }
  100% {
    transform: translate(2px, 2px) rotate(-1deg);
  }
}
</style>
